// extracted by mini-css-extract-plugin
export var alignLeft = "v_qh d_fp d_bG d_dv";
export var alignCenter = "v_bP d_fq d_bD d_dw";
export var alignRight = "v_qj d_fr d_bH d_dx";
export var verticalDottedFirstComponent = "v_rK";
export var wrap = "v_rL d_bJ d_cb";
export var topPadding = "v_rM d_cX";
export var compContent = "v_rN d_w";
export var last = "v_rP";
export var box = "v_rQ d_cr";
export var boxWrapper = "v_rR d_w d_bz";
export var boxWrapperFirst = "v_rS d_w d_bz";