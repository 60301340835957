// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "r_q0 d_gv d_cs";
export var heroHeaderCenter = "r_gw d_gw d_cs d_dw";
export var heroHeaderRight = "r_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "r_q1 d_gr d_cw";
export var heroParagraphCenter = "r_gs d_gs d_cw d_dw";
export var heroParagraphRight = "r_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "r_q2 d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "r_q3 d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "r_q4 d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "r_q5 d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "r_q6 d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "r_q7 x_q7";
export var heroExceptionNormal = "r_q8 x_q8";
export var heroExceptionLarge = "r_q9 x_q9";
export var Title1Small = "r_rb x_rb x_r1 x_r2";
export var Title1Normal = "r_rc x_rc x_r1 x_r3";
export var Title1Large = "r_rd x_rd x_r1 x_r4";
export var BodySmall = "r_rf x_rf x_r1 x_sk";
export var BodyNormal = "r_rg x_rg x_r1 x_sl";
export var BodyLarge = "r_rh x_rh x_r1 x_sm";