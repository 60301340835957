// extracted by mini-css-extract-plugin
export var alignLeft = "w_qh d_fp d_bG d_dv";
export var alignCenter = "w_bP d_fq d_bD d_dw";
export var alignRight = "w_qj d_fr d_bH d_dx";
export var verticalSolidComponent = "w_rT";
export var compContent = "w_rN d_r";
export var normal = "w_rV d_Z d_cb";
export var reverse = "w_rW d_Z d_bK";
export var last = "w_rP";
export var empty = "w_rX d_r";
export var empty2 = "w_rY d_bw";
export var borderRight = "w_rZ d_b8 d_c1";
export var borderLeft = "w_r0 d_b9 d_c1";